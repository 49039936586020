// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cloneDeep<T>(obj: Record<string, any>): T {
  return JSON.parse(JSON.stringify(obj));
}

export type TPopupFieldsPeriod = 'h' | 'm' | 'd';

export function getRootDomain(): string {
  const root = location.hostname
    .split('.')
    .reverse()
    .splice(0, 2)
    .reverse()
    .join('.');
  // localhost doesn't supports (.) before it
  return root === 'localhost' ? root : '.' + root;
}

export function getTimeStamp(date = new Date(), resetTime = false): number {
  if (resetTime) {
    date.setHours(0, 0, 0, 0);
  }
  return +(date.getTime() / 1000).toFixed(0);
}

export function getDifferenceDays(date1: Date, date2 = new Date()): number {
  return Math.round(
    Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
  );
}

export function getDateDifference(
  date1: number,
  period: TPopupFieldsPeriod = 'h',
  date2 = getTimeStamp()
): number {
  const diffSeconds = date2 - date1;
  if (period === 'h') {
    return Math.round(diffSeconds / (60 * 60));
  } else if (period === 'm') {
    return Math.round(diffSeconds / 60);
  } else if (period === 'd') {
    return Math.round(diffSeconds / (60 * 60 * 24));
  }
  return diffSeconds;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function countFilters(filters: any): number {
  let count = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?.groups?.forEach((group: any) => {
    count += group.events?.length + group.fields?.length || 0;
  });
  return count;
}

export function mega(
  value: number,
  fractionSize?: number
): { value: number; power: string } {
  if (!fractionSize || fractionSize < 0) {
    fractionSize = 1;
  }
  let abs = Math.abs(value);
  const rounder = Math.pow(10, fractionSize);
  let key = '';
  const powers = [
    { key: 'Q', value: Math.pow(10, 15) },
    { key: 'T', value: Math.pow(10, 12) },
    { key: 'B', value: Math.pow(10, 9) },
    { key: 'M', value: Math.pow(10, 6) },
    { key: 'K', value: 1000 },
  ];
  for (const item of powers) {
    let reduced = abs / item.value;
    reduced = Math.round(reduced * rounder) / rounder;
    if (reduced >= 1) {
      abs = reduced;
      key = item.key;
      break;
    }
  }
  return { value: abs, power: key };
}
